@import "./normalize";

:root {
  --primary-color: rgba(0, 0, 0);
  --text-color: #fff;
  --color-bg1: rgb(108, 0, 162);
  --color-bg2: rgb(19, 11, 40);;
  --color1: 18, 113, 255;
  --color2: 221, 74, 255;
  --color3: 88, 191, 169;
  --color4: 208, 117, 37;
  --color5: 180, 180, 50;
  --color-interactive: 140, 100, 255;
  --circle-size: 80%;
  --blending: hard-light;
}

/* source-sans-3-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-3-v15-latin-regular.woff2") format("woff2");
}
/* source-sans-3-italic - latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/source-sans-3-v15-latin-italic.woff2") format("woff2");
}
/* source-sans-3-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-3-v15-latin-700.woff2") format("woff2");
}
/* source-sans-3-700italic - latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/source-sans-3-v15-latin-700italic.woff2") format("woff2");
}

* {
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: var(--color-bg2);
  color: var(--text-color);
  font-family: "Source Sans 3", Arial, Helvetica, sans-serif;

  &.preload * {
    transition: none !important;
  }
}

a:link, a:visited, a:active {
  color: var(--text-color);
}
a:hover {
  text-decoration: none;
}

.gradient {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  svg {
    display: none;
  }

  .gradient__container {
    filter: blur(80px);
    width: 100%;
    height: 100%;
  }

  .gradient__g--1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: center center;
    animation: moveVertical 60s ease infinite;

    opacity: 1;
  }

  .gradient__g--2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: moveInCircle 40s reverse infinite;

    opacity: 1;
  }

  .gradient__g--3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: moveInCircle 80s linear infinite;

    opacity: 1;
  }

  .gradient__g--4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 80s ease infinite;

    opacity: 0.7;
  }

  .gradient__g--5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 40s ease infinite;

    opacity: 1;
  }

  .gradient__g--interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  z-index: 999;
}

.wrapper__spacer {
  &--1 {
    flex: 1;
  }
  &--2 {
    flex: 2;
  }
}

.wrapper__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 920px;
  align-items: stretch;
}

a.button {
  font-size: 18px;
  padding: 12px 12px;
  margin: 1rem auto;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 50px;
  color: var(--text-color);
  border: 3px solid var(--text-color);
  max-width: 300px;
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  > span.button__icon {
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: column;

    > svg {
      width: 100%;
      height: 100%;
      color: var(--text-color);
    }
  }

  > span.button__label {
    flex: 1;
    margin-right: 28px;
  }

  &:hover {
    background-color: var(--text-color);
    color: var(--color-bg2);

    > span.button__icon {
      > svg {
        color: var(--color-bg2);
      }
    }
  }
}

section.linkList {
  margin-top: 2rem;
}

.linkList__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wrapper__footer {
  margin-bottom: 1rem;
  ul.legals {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin-right: 1rem;
    }
  }

  a:link, a:visited, a:active {
    color: var(--text-color);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

body.default {
  .wrapper__content {
    text-align: center;
  }
}

body.page {
  .article--main {
    max-width: 920px;
    margin: 2rem auto;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.3);
  }
}
